import SectionTitle from "../../../../common/sectionTitle";

import data from "../../../../assets/data/socialProfile";




import CTAStyleWrapper from "./Cta.style";

const CTA = () => {

  return (
    <CTAStyleWrapper>
      <div className="container">
        <SectionTitle
          className="bithu_title_section text-center"
          title="Join Our community &amp; get Early access"
        ></SectionTitle>
        <div className="bithu_v1_cta_content">

          <div className="cta_social_links">
            <ul>
              {data?.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target="_blank">
                    {item.thumb ? (
                      <img src={item.thumb} alt="bithu nft profiles" />
                    ) : (
                      item.icon
                    )}
                  </a>
                  <div
                    className="social_hover_shape"
                  />
                </li>
              ))}
            </ul>
          </div>


        </div>
      </div>
    </CTAStyleWrapper>
  );
};

export default CTA;
