import styled from "styled-components";



const CTAStyleWrapper = styled.section`
  .bithu_title_section {
    width: 50%;
    margin: 0 auto;
    img {
      display: none;
    }

    h2,
    h3 {
    font-family: 'Hackney', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 92px;
    margin: 0;
    }
  }
  .title-emo {
    font-family: "Permanent Marker", cursive;
  font-weight: 400;
  font-style: normal;
    text-align: center;
    font-size: 50px;
  }

  .bithu_v1_cta_content {
    position: relative;
    z-index: 1;
  }

  .join_comunity_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 430px;
    margin: auto;
    margin-top: 47px;
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
  }

  .cta_social_links {
    max-width: 840px;
    margin: auto;
    margin-bottom: 70px;
    margin-top: 30px;
    position: relative;
    z-index: 1;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      padding: 0;
      li {
        width: 11%;
        height: 60px;
        background: rgba(255, 255, 255, 0.0);
        backdrop-filter: blur(0px);
        display: flex;
        align-items: center;
        justify-content: center;
        a {
          color: #ffffff;
          height: 100%;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg {
            font-size: 20px;
          }
          img {
            width: 40px;
            height: 40px;
          }
        }
        .social_hover_shape {
          position: absolute;
          top: 7px;
          left: 7px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.4s;
        }

        &:hover {
          transform: scale(1.3); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
          }
        }
      }
      li + li {
        margin-left: 15px;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .bithu_title_section {
      width: 60%;

      h3 {
    font-family: 'Hackney', sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 42px;
    }



    }
    .title-emo {
      font-size: 40px;
    }
  }
  @media only screen and (max-width: 575px) {
    .join_comunity_btns {
      max-width: 400px;
      .wishlist_btn {
        width: 46%;
        font-size: 14px;
      }
      .join_discord_btn {
        width: 46%;
        font-size: 14px;
      }
    }

    .cta_social_links {
      max-width: 425px;

      ul {
        li {
          height: 45px;
          width: 12%;
          a {
            img {
              height: auto;
            }
            svg {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  @media (max-width: 425px) {
    .bithu_title_section {
      width: 85%;
    }
    .title-emo {
      font-size: 30px;
    }
    .join_comunity_btns {
      max-width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .wishlist_btn {
        height: 100px;
        font-size: 16px;
      }
    }

    .footer_social_links {
      ul {
        li {
          height: 40px;
          width: 12%;
          a {
            svg {
              font-size: 12px;
            }
            img {
              height: 12px;
            }
          }
        }
      }
    }
  }
`;

export default CTAStyleWrapper;
