
import BannerV1Wrapper from "./Banner.style";
import characterThumb from "../../../../assets/images/nft/Character1.png";
import button from "../../../../assets/images/icon/button.png";
import Button from "../../../../common/button";




const Banner = () => {


  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">


            <div className="bithu_v1_baner_right">

              
              <div className="bithu_v1_baner_right_img_sect">


                <div className="img_zoom">
                  <img src={characterThumb} alt="avater" />
                </div>
              </div>
            </div>
          
        </div>

        <div className="join_comunity_btns">
            <Button className="wishlist_btn" style={{ backgroundImage:`url(${button})`,backgroundRepeat:"no-repeat",backgroundSize:"contain" }}>
              Mint Now!
            </Button>
          </div>

      </div>
    </BannerV1Wrapper>






  );
};

export default Banner;
