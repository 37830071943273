import nftImg1 from "../images/nft/jokevip1.png";
import nftImg2 from "../images/nft/jokevip2.png";
import nftImg3 from "../images/nft/jokevip3.png";
import nftImg4 from "../images/nft/jokevip4.png";
import nftImg5 from "../images/nft/jokevip5.png";
import nftImg6 from "../images/nft/jokevip6.png";
import nftImg7 from "../images/nft/jokevip7.png";
import nftImg8 from "../images/nft/jokevip8.png";
import nftImg9 from "../images/nft/jokevip9.png";
import nftImg10 from "../images/nft/jokevip10.png";
import nftImg11 from "../images/nft/jokevip11.png";
import nftImg12 from "../images/nft/jokevip12.png";

const data = [
  nftImg1,
  nftImg2,
  nftImg3,
  nftImg4,
  nftImg5,
  nftImg6,
  nftImg7,
  nftImg8,
  nftImg9,
  nftImg10,
  nftImg11,
  nftImg12,
];

export default data;
