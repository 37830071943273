import styled, { keyframes } from "styled-components";
import sectionBgImage from "../../../../assets/images/bg/h2_banner_bg.png";



const rotate = keyframes`
from {
  transform: rotate(0deg);
}

to {
  transform: rotate(360deg);
}
`;

const zoom = keyframes`
0% {
    transform: scale(0.95, 0.95);
  }
  50% {
    transform: scale(1.0, 1.0);
  }
  100% {
    transform: scale(0.95, 0.95);
`;

const BannerV1Wrapper = styled.section`
  background: url(${sectionBgImage}), linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(72,53,94,1) 95%);
  min-height: 100px;
  width: 100%;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 152px;
  padding-bottom: 100px;

    .join_comunity_btns {
    display: none;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: auto;
    margin-top: 87px;
    margin-bottom: 0px;
    position: relative;
    z-index: 1;
  }





  .img_zoom {
          animation: ${zoom} 4s ease-in-out infinite;
          position: absolute;
        }

  

    .banner_buttons {
      display: flex;
      column-gap: 30px;
      margin-top: 58px;
    }

    .coin-info {
      display: flex;
      flex-direction: column;
      margin-top: 37px;
      span {
        font-family: "Bakbak One";
        font-size: 18px;
        line-height: 36px;
        text-transform: uppercase;
        color: rgba(255, 255, 255, 0.8);

        & .highlighted {
          color: #ffe600;
        }
      }
    }
  }

  .bithu_v1_baner_right {
    max-width: 500px;
    width: 100%;
    margin-left: auto;
    margin-right:auto;

    h2 {
      font-family: 'HorrorScribbles', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 60px;
      line-height: 80px;
      text-transform: uppercase;
      color: #ffffff;
      margin-bottom: 32px;
    }

    h3 {
      font-family: 'HorrorScribbles', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      color: #ffffff;
    }

    .banner_buttons {
      display: flex;
      column-gap: 30px;
      margin-top: 58px;
    }

    .mint_live_circle_sect {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 140px;
      width: 140px;
      border-radius: 50%;
      border: none;
      background: #ffe600;
      position: absolute;
      top: -65px;
      left: -65px;
      z-index: 1;

      .mint_live_circle {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .mint_live_text {
          animation: ${rotate} 20s linear infinite;
          position: absolute;
        }
      }
    }

    .bithu_v1_baner_right_img_sect {
      position: relative;
      width: 100%;
      height: 550px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
      margin-right:auto;
      margin-bottom: 50px;



      .bithu_v1_baner_right_img {
        position: relative;
        z-index: 1;
      }
    }
  }



  @media only screen and (max-width: 1199px) {



    .bithu_v1_baner_right {
      h2 {
        font-size: 50px;
        line-height: 65px;
      }

      h3 {
        font-size: 34px;
        line-height: 38px;
      }

      .baner_buttons {
        margin-top: 40px;
      }
    }
  }

  @media only screen and (max-width: 991px) {
    .bithu_v1_baner_right {
      margin-top: 0px;
      justify-content: center;
    }

    .bithu_v1_baner_right {
      margin: 10px auto;
      padding: 0px 68px;
      max-width: 568px;
      width: 100%;

      .bithu_v1_baner_right_img {
        width: 55%;
      }
    }
  }

  @media only screen and (max-width: 767px) {



    .bithu_v1_baner_right {
      h2 {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 20px;
      }

      h3 {
        font-size: 28px;
        line-height: 38px;
        
      }

      .banner_buttons{
        margin-top: 25px;
      }
    }

  }

  @media only screen and (max-width: 480px) {


    .bithu_v1_baner_right {
    
      h2 {
        font-size: 37px;
      }

      h3 {
        font-size: 22px;
      }

      .coin-info {
        font-size: 14px;
        line-height: 29px;
      }

      .banner_buttons{
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
        
            .bithu-btn + .bithu-btn {
                margin-top: 20px;
            }
        }
    }

    .bithu_v1_baner_right {

      height: 500px;
      margin-top: -95px;
      
      
    }
  }

  @media (max-width: 375px) {


      .bithu_v1_baner_right_img {
        position: relative;
        margin-top: -15px;
        z-index: 1;
      }

      .bithu_v1_baner_right {
      height: 450px;
      margin-top: -95px;
      }
    }



    .bithu_v1_baner_right {
    
      h2 {
        font-size: 35px;
      }
      h3 {
        font-size: 22px;
      }
    }
  }

  @media (max-width: 360px) {


    .bithu_v1_baner_right {
      .coin-info {
        font-size: 13px;
      }
    }
  }
`;

export default BannerV1Wrapper;
