import checkIcon from "../../images/blog/arrow.png";
import spinnerIcon from "../../images/icon/roadmap_spiner.png"

import thumb1 from "../../images/icon/road1.png";
import thumb2 from "../../images/icon/road2.png";
import thumb3 from "../../images/icon/road3.png";
import thumb4 from "../../images/icon/road4.png";
import thumb5 from "../../images/icon/road5.png";
import thumb6 from "../../images/icon/road6.png";
import thumb7 from "../../images/icon/road7.png";
import thumb8 from "../../images/icon/road8.png";
import thumb9 from "../../images/icon/road9.png";
import thumb10 from "../../images/icon/road10.png";
import thumb11 from "../../images/icon/road11.png";
import thumb12 from "../../images/icon/road12.png";
import thumb13 from "../../images/icon/road10.png";
import thumb14 from "../../images/icon/road14.png";


import thumb1_1 from "../../images/icon/roadn1.png";
import thumb2_2 from "../../images/icon/roadn2.png";
import thumb3_3 from "../../images/icon/roadn3.png";
import p10 from "../../images/icon/p10.png";
import p20 from "../../images/icon/p20.png";
import p40 from "../../images/icon/p40.png";
import p50 from "../../images/icon/p50.png";
import p70 from "../../images/icon/p70.png";
import p80 from "../../images/icon/p80.png";
import p90 from "../../images/icon/p90.png";
import p100 from "../../images/icon/p100.png";
import next1 from "../../images/icon/next1.png";
import next2 from "../../images/icon/next2.png";
import next3 from "../../images/icon/next3.png";


const data = [
  {
    progressIcon: checkIcon,
    progress: thumb1_1,
    text: "early airdrop of 50 NFTS reserved to project helpers",
    thumb: thumb1,
  },

  {
    progressIcon: spinnerIcon,
    progress: thumb2_2,
    text: "pre-sale for whitelisted mem3ers",
    thumb: thumb2,
  },

  {
    progressIcon: spinnerIcon,
    progress: thumb3_3,
    text: "project launch !!! start of the sell-trough percentages milestones",
    thumb: thumb3,
  },

  {
    progressIcon: spinnerIcon,
    progress: p10,
    text: "if you're good at something...",
    thumb: thumb4,
  },

  {
    progressIcon: spinnerIcon,
    progress: p20,
    text: "unlock access to an holders-exclusive Q&A community area to interact with the artist",
    thumb: thumb5,
  },

  {
    progressIcon: spinnerIcon,
    progress: p40,
    text: "donate 10% of the revenue earned up to this point to a community-selected charitable organization specializing in clowntherapy",
    thumb: thumb6,
  },

  {
    progressIcon: spinnerIcon,
    progress: p50,
    text: "creation and delivery of the holder's NFT in its unique and signed phisical form",
    thumb: thumb7,
  },

  {
    progressIcon: spinnerIcon,
    progress: p70,
    text: "holders-exclusive online merchandise store opening",
    thumb: thumb8,
  },

  {
    progressIcon: spinnerIcon,
    progress: p80,
    text: "design and open the jokevip's HA-HA-HALL OF FAME to chronicles the history of jokevip holders",
    thumb: thumb9,
  },

  {
    progressIcon: spinnerIcon,
    progress: p90,
    text: "clownllector's game kick-off! holders can now unlock even more exclusive rewards",
    thumb: thumb10,
  },

  {
    progressIcon: spinnerIcon,
    progress: p100,
    text: "jokevip begins to 'jokevipize' the world and kick-off an explosive and expansion-based phase 2",
    thumb: thumb11,
  },

  {
    progressIcon: spinnerIcon,
    progress: next1,
    text: "creation of a new generation-2 collection!",
    thumb: thumb12,
  },

  {
    progressIcon: spinnerIcon,
    progress: next2,
    text: "next phase clownllector's game features and rewards !!",
    thumb: thumb13,
  },

  {
    progressIcon: spinnerIcon,
    progress: next3,
    text: "jokevip artist identity official reveal !!!",
    thumb: thumb14,
  },

];

export default data;
