import styled from "styled-components";

const ConinInfoSliderWrapper = styled.section`
  background: #ffc220;
  padding: 20px 0;
  font-family: 'HorrorScribbles', sans-serif;
  font-weight: 600;
  font-size: 28px;
  line-height: 18px;
  text-transform: uppercase;
  white-space: pre;
  color: #111111;

  .slide_item {
    display: flex;
    align-items: center;
    column-gap: 20px;

    img {
      display: inline-block !important;
    }
  }


  @media only screen and (max-width: 799px) {
  font-size: 18px;

  }




`;

export default ConinInfoSliderWrapper;
