const data = [
  {
    title: "What Is MiNFT?",
    text: "MiNFT is a unique platform dedicated to the creation, buying, and selling of Non-Fungible Tokens (NFTs). It allows users to mint their digital assets, trade them securely, and engage with a vibrant community of creators and collectors.",
  },
  {
    title: "How Can I Buy and Invest in NFTs?",
    text: "To buy and invest in NFTs, you need to create an account on MiNFT, connect a digital wallet (like MetaMask), and deposit funds. Browse the marketplace for NFTs that interest you, and follow the prompts to make a purchase. Always ensure to research the NFTs and their creators before investing.",
  },
  {
    title: "Why Should I Choose MiNFT?",
    text: "MiNFT stands out due to its user-friendly interface, robust security measures, and a strong community focus. The platform supports various creators, ensuring a diverse range of NFTs. Additionally, MiNFT offers educational resources to help new users understand the NFT space better. ",
  },
  {
    title: "Where Can I Buy and Sell NFTs?",
    text: "You can buy and sell NFTs directly on the MiNFT marketplace. This platform provides a secure environment for transactions, allowing you to manage your NFT portfolio efficiently. ",
  },
  {
    title: "How Secure Is This Token?",
    text: "MiNFT employs advanced security protocols, including blockchain technology and encryption, to protect users' assets and personal information. Regular audits and updates further enhance the platform's security.",
  },
  {
    title: "What Is Your Contract Address?",
    text: "The contract address for MiNFT can typically be found on the official website or within the platform's documentation. Ensure to verify this information through official channels to avoid scams.",
  },
];

export default data;
