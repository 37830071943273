import GlobalStyles from "../assets/styles/GlobalStyles";
import Header from "../components/section/header/v1/Header";
import Layout from "../common/layout";
import Banner from "../components/section/banner/v1";
import CoinInfoSlider from "../components/section/coinInfoSlider";
import About from "../components/section/about/v1";
import Portfolio from "../components/section/portfolio";
import RoadMap from "../components/section/roadMap/v3";
import Team from "../components/section/team/v1";
import FAQ from "../components/section/faq/v1"; 
import Footer from "../components/section/footer/v1";


const HomeV1 = () => {

  return (
    <Layout>
      <GlobalStyles />  
      <Header />
      <Banner />
      <CoinInfoSlider />
      <About />
      <Portfolio />
      <RoadMap /> 
      <Team />
      <FAQ />
      <Footer />
    </Layout>
  );
};

export default HomeV1;
