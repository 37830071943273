const RoadMapItem = ({ progressIcon, progress, title, text, thumb }) => {
  return (
    <div className="bithu_roadmap_item"> 




{/*

      <div className="roadmap_parcentage">
        <span>{progress}</span>
        <span className="parcentage">%</span>
      </div> 
*/}
      <div className="bithu_roadmap_content"> 
        

      <div className="roadmap_img">
        <div className="spacer">
        <img src={progress} alt="roadmap" />
        </div>
        <div className="spacer2">
        <img src={thumb} alt="roadmap" />
        </div>
      </div>




        <div className="bithu_roadmap_text">
        <div className="roadmap_heading">
            <img src={progressIcon} alt="bithu nft progress" />
          </div>
          <p>{text}</p>
        </div>
      </div>

    </div>
  );
};

export default RoadMapItem;
