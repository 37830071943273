import shapeIcon from "../images/icon/mint_live_icon.svg";

const data = [
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
  {
    icon: shapeIcon,
    text: "Get your whitelist spot!",
  },
];

export default data;
