
import aboutCardIcon2 from "../../images/icon/aboutus_card_icon2.svg"


 const data = {
    aboutDescription1: 'What if… an extremely easy-laughing guy decides to become an artist? JOKEvip - why so famous?" is a CRAZY collection of 3,690 NFT artworks, each crafted ONE BY ONE by the mysterious artist Jokevip with CHAOTIC ACCURACY, NON-STOP study, and TOTAL dedication to maximize their uniqueness and, of course, their value.',
    aboutDescription2: 'Insanely DOPE, isn’t it? Ha-ha-ha...! Jokevip\'s digital art is transformative, explorative, extremely parodic celebrating pop culture in ways you’ve NEVER seen before! In other words, each Jokevip NFT artwork is a more-than-smiling tribute to iconic figures of pop culture!',
    aboutInfo: [
        {
            icon: aboutCardIcon2,
            title: "Verifyed ownership",
            text: "Become the exclusive owner of a unique item in the world. Certified by Ethereum blockchain."
        },
        {
            icon: aboutCardIcon2,
            title: "Unique phisical reproduction",
            text: 'When you invest into a digital Jokervip NFT, you will receive in addition a phisical version of it in a unique, signed and numbered edition.'
        },
        {
            icon: aboutCardIcon2,
            title: "Visionary Community",
            text: 'Join one of the most creative NFT collectors Communities, focused into constantly increase the concept of all-round value!'
        }
    ]
}

export default data;