import { BsXLg } from "react-icons/bs";
import logo from "../../../../assets/images/logo.png";
import data from "../../../../assets/data/socialProfile";
import MobileMenuStyleWrapper from "./MobileMenu.style";
import { useState } from "react";

const MobileMenu = ({ mobileMenuhandle }) => {
  // Stato per tracciare se il menu è aperto
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  // Funzione per chiudere il menu quando si clicca su un link
  const handleMenuClick = () => {
    setIsMenuOpen(false);
    mobileMenuhandle(); // Chiamata alla funzione che gestisce il menu a livello superiore
  };

  return (
    <MobileMenuStyleWrapper className={`bithu_mobile_menu ${isMenuOpen ? "open" : "closed"}`}>
      <div className="bithu_mobile_menu_content">
        <div className="mobile_menu_logo">
          <img className="bithu_logo" src={logo} alt="bithu logo" />
          <button
            className="mobile_menu_close_btn"
            onClick={() => mobileMenuhandle()}
          >
            <BsXLg />
          </button>
        </div>
        <div className="bithu_mobile_menu_list">
          <ul>
            <li className="mobile_menu_hide">
              <a href="#home" onClick={handleMenuClick}>Home</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#about" onClick={handleMenuClick}>About</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#portfolio" onClick={handleMenuClick}>Gallery</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#roadmap" onClick={handleMenuClick}>Roadmap</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#team" onClick={handleMenuClick}>Team</a>
            </li>
            <li className="mobile_menu_hide">
              <a href="#faq" onClick={handleMenuClick}>FAQ</a>
            </li>
          </ul>
        </div>

        <div className="cta_social_links">
          <ul>
            {data?.map((item, i) => (
              <li key={i}>
                <a href={item.url}>
                  {item.thumb ? (
                    <img src={item.thumb} alt="bithu nft profiles" />
                  ) : (
                    item.icon
                  )}
                </a>
                <div className="social_hover_shape" />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </MobileMenuStyleWrapper>
  );
};

export default MobileMenu;
