import xIcon from "../images/icon/x.png";
import discordIcon from "../images/icon/discord.png";
import openseaIcon from "../images/icon/opensea.png";
import instagramIcon from "../images/icon/instagram.png";

const data = [
  {
    thumb: xIcon,
    url: "https://x.com/jokevipnft",
  },

  {
    thumb: discordIcon,
    url: "https://discord.gg/wcYU4yUVbr",
  },

  {
    thumb: instagramIcon,
    url: "https://www.instagram.com/jokevip_nft/",
  },

  {
    thumb: openseaIcon,
    url: "#",
  },
];

export default data;
